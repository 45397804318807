import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from '@coreui/react';
import { FormControl, MenuItem, Select, Grid, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useEditManageUIMutation,
  useGetManageUIDataQuery,
} from '../../redux-services';

export interface OutDoorFieldsType {
  iso: number;
  shutter: number;
}

export interface ManageUIContentState {
  putt_limit: number;
  focus: number;
  putt_limit_login: number;
  player_limit: number;
  stats: boolean;
  composite: boolean;
  launch_angle: boolean;
  object_detection: boolean;
  show_products: boolean;
  video_playback_time: number,
  indoor: OutDoorFieldsType;
  outdoor_sunny: OutDoorFieldsType;
  outdoor_overcast: OutDoorFieldsType;
  tutorial_file: any;
  video_file: any;
  delete_file: string;
  video_delete_file: string;
  device_limit: number;
  without_internet: number;
  url: string;
  video_url: string;
  video_preview_url: string;
  force_live: boolean;
  showDebugLogs: boolean;
}

const ManageUIEditor: React.FunctionComponent = (props) => {

  const initialFormState: ManageUIContentState = {
    putt_limit: 0,
    focus: 0,
    putt_limit_login: 0,
    player_limit: 0,
    device_limit: 1,
    stats: false,
    composite: false,
    launch_angle: false,
    object_detection: false,
    show_products: false,
    video_playback_time: 0,
    indoor: {
      iso: 0,
      shutter: 0,
    },
    outdoor_sunny: {
      iso: 0,
      shutter: 0,
    },
    outdoor_overcast: {
      iso: 0,
      shutter: 0,
    },
    tutorial_file: null,
    video_file: null,
    delete_file: '',
    video_delete_file: '',
    without_internet: 1,
    url: '',
    video_url: '',
    video_preview_url: '',
    force_live: false,
    showDebugLogs: false,
  };
  const [formData, setFormData] = useState<{ promo_type: string, promo_data: any[] }>({
    promo_type: "monthly",
    promo_data: [],
  });
  const [formValidation, setFormValidation] =
    useState<ManageUIContentState>(initialFormState);

  const [validated, setValidated] = useState<boolean>(false);
  const [manageUIFields, setManageUIFields] =
    useState<ManageUIContentState>(initialFormState);

  const [editManageUI, result] = useEditManageUIMutation();
  const { data: ManageUIData } = useGetManageUIDataQuery({ getDetail: '' });

  useEffect(() => {
    if (ManageUIData && ManageUIData?.response) {
      let data = ManageUIData?.response;
      const blob = base64toBlob(data?.signedUrl);
      let url = URL.createObjectURL(blob);
      setManageUIFields({
        ...manageUIFields,
        ...data,
        tutorial_file: data?.tutorial_file?.path,
        video_file: data?.video_file?.path,
        url: url ?? '',
        video_url: data?.videoSignedUrl ?? '',
      });
    }
    const thumbnailElement = document.getElementById('images') as HTMLInputElement | null;
    const videoElement = document.getElementById('video') as HTMLInputElement | null;

    if (thumbnailElement) {
      thumbnailElement.value = '';
    }
    if (videoElement) {
      videoElement.value = '';
    }
  }, [ManageUIData]);

  const base64toBlob = (data: string) => {
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if ((name === 'putt_limit') || (name === 'focus') || (name === 'putt_limit_login') || (name === 'player_limit') || (name === 'device_limit') || (name === 'without_internet') || (name === 'video_playback_time')) {
      setManageUIFields({ ...manageUIFields, [name]: Number(value) });
    } else {
      setManageUIFields({ ...manageUIFields, [name]: Boolean(value) });
    }
  };

  const handleDocumentChange = (event: any) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fileUrl = URL?.createObjectURL(file);
    setManageUIFields({
      ...manageUIFields,
      [event.target.name]: file,
      url: fileUrl ?? '',
      delete_file: ManageUIData?.response?.tutorial_file?.fullUrl,
    });
  }

  const handlePromoCode = (event: any) => {
    const file = event.target.files[0];

    if (file.type === 'text/csv') {

      const reader = new FileReader();
      let data: { name: string; url: string; is_applied: boolean; }[] = [];
      reader.onload = (e: any) => {
        const text = e.target.result;
        const rows = text.split('\n');
        if (rows?.length > 0) {
          for (let item of rows) {
            let option = item?.split(',');
            if (option?.length > 0) {
              if (option[0] !== "" && option[1]?.includes("https://")) {
                data.push({
                  name: option[0] ?? "",
                  url: option[1] ?? "",
                  is_applied: false,
                })
              }
            }
          }
        }
      };
      setFormData({ ...formData, promo_data: data });
      reader.readAsText(file);
    } else {
      alert("Please Upload only CSV file, Other File format is not supported");
      const thumbnailElement = document.getElementById('promo_code') as HTMLInputElement | null;
      if (thumbnailElement) {
        thumbnailElement.value = '';
      }
    }
  }

  const selectPromoCode = (event: (SelectChangeEvent | any)) => {
    setFormData({ ...formData, promo_type: event.target.value });
  };

  const handleVideoFileChange = (event: any) => {
    event.preventDefault();
    let file = event.target.files[0];
    let fileUrl = URL?.createObjectURL(file);
    setManageUIFields({
      ...manageUIFields,
      [event.target.name]: file,
      video_preview_url: fileUrl,
      video_delete_file: ManageUIData?.response?.video_file?.fullUrl,
    });
  }

  const handleOutdoorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (e.currentTarget.getAttribute("data-id") === 'outdoor_sunny') {
      const updatedOutdoorSunny: OutDoorFieldsType = {
        ...manageUIFields.outdoor_sunny,
        [name]: Number(value),
      }
      setManageUIFields({ ...manageUIFields, outdoor_sunny: updatedOutdoorSunny })
    }
    if (e.currentTarget.getAttribute("data-id") === 'outdoor_overcast') {
      const updatedOutdoorSunny: OutDoorFieldsType = {
        ...manageUIFields.outdoor_overcast,
        [name]: Number(value),
      }
      setManageUIFields({ ...manageUIFields, outdoor_overcast: updatedOutdoorSunny })
    }
    if (e.currentTarget.getAttribute("data-id") === 'indoor') {
      const updatedOutdoorSunny: OutDoorFieldsType = {
        ...manageUIFields.indoor,
        [name]: Number(value),
      }
      setManageUIFields({ ...manageUIFields, indoor: updatedOutdoorSunny })
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...initialFormState,
      });
      let data = new FormData();
      data.append('putt_limit', String(manageUIFields.putt_limit));
      data.append('putt_limit_login', String(manageUIFields.putt_limit_login));
      data.append('focus', String(manageUIFields.focus));
      data.append('player_limit', String(manageUIFields.player_limit));
      data.append('video_playback_time', String(manageUIFields.video_playback_time));
      data.append('stats', String(manageUIFields.stats));
      data.append('composite', String(manageUIFields.composite));
      data.append('launch_angle', String(manageUIFields.launch_angle));
      data.append('object_detection', String(manageUIFields.object_detection));
      data.append('show_products', String(manageUIFields.show_products));
      data.append('indoor', JSON.stringify(manageUIFields.indoor) as string);
      data.append('outdoor_sunny', JSON.stringify(manageUIFields.outdoor_sunny) as string);
      data.append('outdoor_overcast', JSON.stringify(manageUIFields.outdoor_overcast) as string);
      data.append('tutorial_file', manageUIFields.tutorial_file);
      data.append('video_file', manageUIFields.video_file);
      data.append('delete_file', manageUIFields.delete_file);
      data.append('video_delete_file', manageUIFields.video_delete_file);
      data.append('device_limit', String(manageUIFields.device_limit));
      data.append('without_internet', String(manageUIFields.without_internet));
      data.append('force_live', String(manageUIFields?.force_live));
      data.append('showDebugLogs', String(manageUIFields?.showDebugLogs));
      data.append('id', ManageUIData?.response?._id);

      // Promo Code
      // data.append('promo_code', JSON.stringify(formData.promo_data));
      // data.append('promo_type', formData.promo_type);

      editManageUI(data);
    }
  };

  return (
    <CRow>
      <CCol md={12}>
        <CCard className="mb-4">
          <CCardHeader style={{ backgroundColor: "#cbcaca", fontSize: "16px" }}>
            <strong>Manage UI</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CRow className="g-3 p-3">
                <CCard>
                  <CCardHeader style={{ margin: "0px -8px", fontSize: "15px" }}>
                    <strong>Free Trial</strong>
                  </CCardHeader>
                  <CCardBody className="bg-transparent">
                    <CRow className="g-3">
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Putt Limit*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Putt Limit"
                          value={manageUIFields.putt_limit}
                          name="putt_limit"
                          min={0}
                          feedbackInvalid="Please provide a Putt Value."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Putt Limit Login*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Putt Limit Login"
                          value={manageUIFields.putt_limit_login}
                          name="putt_limit_login"
                          min={0}
                          feedbackInvalid="Please provide a Putt Login Value."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Player Limit*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Player Limit"
                          value={manageUIFields.player_limit}
                          name="player_limit"
                          min={0}
                          feedbackInvalid="Please provide a Player Value."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Video Playback Time(ms)*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Device Limit"
                          value={manageUIFields.video_playback_time}
                          name="video_playback_time"
                          min={1}
                          feedbackInvalid="Please provide a video playback time greater than 0."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Device Limit*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Device Limit"
                          value={manageUIFields.device_limit}
                          name="device_limit"
                          min={1}
                          feedbackInvalid="Please provide a device limit greater than 0."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Without Internet*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Without Internet"
                          value={manageUIFields.without_internet}
                          name="without_internet"
                          min={0}
                          feedbackInvalid="Please provide a Without Internet."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Stats*</CFormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields.stats}
                            label="stats"
                            name="stats"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Yes</MenuItem>
                            <MenuItem value={false as any}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Composite*</CFormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields.composite}
                            label="composite"
                            name="composite"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Yes</MenuItem>
                            <MenuItem value={false as any}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Launch Angle*</CFormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields.launch_angle}
                            label="launch_angle"
                            name="launch_angle"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Yes</MenuItem>
                            <MenuItem value={false as any}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Object Detection*</CFormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields.object_detection}
                            label="object_detection"
                            name="object_detection"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Yes</MenuItem>
                            <MenuItem value={false as any}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Show Products*</CFormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields.show_products}
                            label="show_products"
                            name="show_products"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Show</MenuItem>
                            <MenuItem value={false as any}>Hide</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Focus*</CFormLabel>
                        <CFormInput
                          id="boh-name"
                          type="number"
                          placeholder="Focus"
                          value={manageUIFields.focus}
                          name="focus"
                          // min={0}
                          feedbackInvalid="Please provide a Focus Value."
                          required
                          onChange={(e) => handleChange(e)}
                        />
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Force Live*</CFormLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields.force_live}
                            label="force_live"
                            name="force_live"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Yes</MenuItem>
                            <MenuItem value={false as any}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <FormControl fullWidth>
                          <CFormLabel htmlFor="boh-name">Debug Logs*</CFormLabel>
                          <Select
                            required
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={manageUIFields?.showDebugLogs}
                            label="Debug Logs"
                            name="showDebugLogs"
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value={true as any}>Yes</MenuItem>
                            <MenuItem value={false as any}>No</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
                <CCard>
                  <CCardHeader style={{ margin: "0px -8px", fontSize: "15px" }}>
                    <strong>Presets</strong>
                  </CCardHeader>
                  <CCardBody className="bg-transparent">
                    <CRow className="g-3">
                      <CCardBody className="bg-transparent manage_box">
                        <CFormLabel>Indoor</CFormLabel>
                        <CRow className="g-3">
                          <CCol md={6}>
                            <CFormLabel htmlFor="boh-name">ISO*</CFormLabel>
                            <CFormInput
                              id="boh-name"
                              type="number"
                              placeholder="ISO"
                              value={manageUIFields.indoor?.iso}
                              name="iso"
                              data-id={'indoor'}
                              min={0}
                              feedbackInvalid="Please provide a ISO."
                              required
                              onChange={(e) => handleOutdoorChange(e)}
                            />
                          </CCol>
                          <CCol md={6}>
                            <CFormLabel htmlFor="boh-name">Shutter*</CFormLabel>
                            <CFormInput
                              id="boh-name"
                              type="number"
                              placeholder="Putt Limit"
                              value={manageUIFields.indoor?.shutter}
                              name="shutter"
                              data-id={'indoor'}
                              min={0}
                              feedbackInvalid="Please provide a Shutter."
                              required
                              onChange={(e) => handleOutdoorChange(e)}
                            />
                          </CCol>
                        </CRow>
                      </CCardBody>
                      <CCardBody className="bg-transparent manage_box ">
                        <CFormLabel>Outdoor Sunny</CFormLabel>
                        <CRow className="g-3">
                          <CCol md={6}>
                            <CFormLabel htmlFor="boh-name">ISO*</CFormLabel>
                            <CFormInput
                              id="boh-name"
                              type="number"
                              placeholder="ISO"
                              value={manageUIFields.outdoor_sunny?.iso}
                              name="iso"
                              data-id={'outdoor_sunny'}
                              min={0}
                              feedbackInvalid="Please provide a ISO."
                              required
                              onChange={(e) => handleOutdoorChange(e)}
                            />
                          </CCol>
                          <CCol md={6}>
                            <CFormLabel htmlFor="boh-name">Shutter*</CFormLabel>
                            <CFormInput
                              id="boh-name"
                              type="number"
                              placeholder="Putt Limit"
                              value={manageUIFields.outdoor_sunny?.shutter}
                              name="shutter"
                              data-id={'outdoor_sunny'}
                              min={0}
                              feedbackInvalid="Please provide a Shutter."
                              required
                              onChange={(e) => handleOutdoorChange(e)}
                            />
                          </CCol>
                        </CRow>
                      </CCardBody>
                      <CCardBody className="bg-transparent manage_box">
                        <CFormLabel>Outdoor OverCast</CFormLabel>
                        <CRow className="g-3">
                          <CCol md={6}>
                            <CFormLabel htmlFor="boh-name">ISO*</CFormLabel>
                            <CFormInput
                              id="boh-name"
                              type="number"
                              placeholder="ISO"
                              value={manageUIFields.outdoor_overcast?.iso}
                              name="iso"
                              data-id={'outdoor_overcast'}
                              min={0}
                              feedbackInvalid="Please provide a ISO."
                              required
                              onChange={(e) => handleOutdoorChange(e)}
                            />
                          </CCol>
                          <CCol md={6}>
                            <CFormLabel htmlFor="boh-name">Shutter*</CFormLabel>
                            <CFormInput
                              id="boh-name"
                              type="number"
                              placeholder="Putt Limit"
                              value={manageUIFields.outdoor_overcast?.shutter}
                              name="shutter"
                              data-id={'outdoor_overcast'}
                              min={0}
                              feedbackInvalid="Please provide a Shutter."
                              required
                              onChange={(e) => handleOutdoorChange(e)}
                            />
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CRow>
                  </CCardBody>
                </CCard>
                <CCard>
                  <CCardHeader style={{ margin: "0px -8px", fontSize: "15px" }}>
                    <strong>Tutorial Files</strong>
                  </CCardHeader>
                  <CCardBody className="bg-transparent">
                    <CRow className="g-3">
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Tutorial Pdf</CFormLabel>
                        <CFormInput
                          id="video"
                          type="file"
                          placeholder="Choose a file"
                          name="tutorial_file"
                          accept='application/pdf'
                          onChange={(e) => handleDocumentChange(e)}
                        />
                        {manageUIFields.url !== '' &&
                          <Grid item md={12} className='pdf-icon'>
                            <iframe src={manageUIFields.url} width="500" height="300" className="file_preview" />
                          </Grid>
                        }
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Tutorial Video File (*.mp4)</CFormLabel>
                        <CFormInput
                          id="images"
                          type="file"
                          placeholder="Choose a file"
                          name="video_file"
                          accept="video/mp4,video/x-m4v,video/*"
                          onChange={(e) => handleVideoFileChange(e)}
                        />
                        {manageUIFields.video_url !== '' &&
                          <Grid item md={12} className='pdf-icon'>
                            {manageUIFields?.video_preview_url == '' ?
                              <video controls width="500" height="300" className="file_preview">
                                <source src={manageUIFields?.video_url} />
                                Your browser does not support the video tag.
                              </video>
                              :
                              <iframe src={manageUIFields?.video_preview_url} width="500" height="300" className="file_preview" />
                            }
                          </Grid>
                        }
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>

                {/* <CCard>
                  <CCardHeader style={{ margin: "0px -8px", fontSize: "15px" }}>
                    <strong>Promo Code</strong>
                  </CCardHeader>
                  <CCardBody className="bg-transparent">
                    <CRow className="g-3">
                      <CCol md={6}>
                        <FormControl fullWidth size='small'>
                          <CFormLabel htmlFor="boh-name">Promo Code Type</CFormLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData.promo_type}
                            label="Select Promo Code"
                            onChange={selectPromoCode}
                            renderValue={() => formData.promo_type !== "" ? formData.promo_type.charAt(0).toUpperCase() + formData.promo_type.slice(1) + ' Promo Code' : ""}
                          >
                            <MenuItem value={'monthly'} selected={formData.promo_type === 'monthly'}>Monthly Promo Code</MenuItem>
                            <MenuItem value={'yearly'} selected={formData.promo_type === 'yearly'}>Yearly Promo Code</MenuItem>
                          </Select>
                        </FormControl>
                      </CCol>
                      <CCol md={6}>
                        <CFormLabel htmlFor="boh-name">Upload Promo Code CSV File</CFormLabel>
                        <CFormInput
                          id="promo_code"
                          type="file"
                          placeholder="Choose a file"
                          name="promo_code"
                          accept='.csv'
                          onChange={handlePromoCode}
                        />
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard> */}
                <CCol md={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/dashboard">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
export default ManageUIEditor;

